$c-primary: #FF5C35;
$c-secandary: #402020;
$dots: false;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'Rubik', sans-serif;
$font-family-heading: 'Rubik', sans-serif;


            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;



  .description {
    text-align: left;

    .langRow {
      border-bottom: 1px solid #ccc;
      padding: 1rem 0.3125rem;
    }

    .img-flag {
      width: 2rem; //32
      height: 1.125rem; //18
    }
  }
}
