$c-primary: #FF5C35;
$c-secandary: #402020;
$dots: false;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'Rubik', sans-serif;
$font-family-heading: 'Rubik', sans-serif;


            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  height: 100%;

  .buttons {
    display: flex;
    justify-content: space-between;
    height: 100%;

    button {
      width: auto;
      height: 100%;
      min-width: 57%;
      white-space: break-spaces;
      line-height: 1;

      &:first-child {
        text-align: left;
        min-width: min-content !important;
      }

      + button {
        margin-left: .5rem;
      }
    }
  }

  .btnFull {
    width: 100%;
    height: 100%;

    button {
      width: 100%;
      height: 100%;
    }
  }
}
